<template>
  <el-dialog
    v-model="isOpen"
    :title="alert.title"
    width="750px"
    top="6vh"
    class="el-dialog--spaceless-body"
    append-to-body
  >
    <template #header>
      <span>
        <icon-warning class="tw-text-text-light" /> {{ alert.title }}
      </span>
    </template>
    <div>
      <p
        class="tw-mb-6 tw-mx-5"
        v-html="(alert.description || '').replace(/\n/g, '<br>')"
      />
      <div
        v-if="alert.link"
        class="video-wrapper"
      >
        <iframe
          width="560"
          height="315"
          :src="`https://www.youtube-nocookie.com/embed/${getYouTubeID(alert.link)}?autoplay=1`"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
        <div class="video-placeholder tw-flex tw-items-center tw-justify-center">
          <icon-loading />
        </div>
      </div>
    </div>

    <template #footer>
      <div

        class="tw-text-center tw-w-full"
      >
        <el-button
          size="small"
          @click="isOpen = false"
        >
          Zavřít
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
      alert: {},
    };
  },

  async mounted() {
    try {
      this.isLoading = true;
      const response = await useApi.get('/messages');
      const messageId = useAuthStore().user?.lang === 'ua' ? 2 : 1;
      const message = response?.results?.find((message) => message.id === messageId);

      if (message) {
        this.alert = message;
        this.isOpen = true;
      }

      this.isLoading = false;
    } catch (error) {
      console.error(error);
    }
  },
};
</script>
