<template>
  <main
    :class="useRoute().meta?.fillHeight ? 'FillHeight' : ''"
  >
    <template v-if="useAuthStore().isLoggedIn">
      <div
        v-if="useRoute().meta?.lightBackground"
        class="layout-fixed-bg tw-bg-light"
      />

      <ClientAppHeader
        v-if="!useUIStore().accessibility.fullscreen"
      />

      <slot />

      <ConfirmEmergencyDialog />
      <ClientInAppAlert />
      <CapacitorCodePush
        v-if="useRuntimeConfig().public.isIOSApp"
      />
    </template>
    <div
      v-else
      v-loading="true"
      class="tw-min-h-20"
    />
  </main>
</template>

<script setup>
// Refs
const i18n = useNuxtApp().$i18n;
const route = useRoute();
const localePath = useLocalePath();

// Computed
const requests = computed(() => useNuxtApp().$store.state.client.requests.requests);

// Watchers
watch(() => useAuthStore().user?.lang, () => {
  if (useAuthStore().user?.lang) {
    i18n.setLocale(useAuthStore().user?.lang);
  }
}, { immediate: true });

// Feature - redirect to ongoing request
watch(requests, () => {
  if (!route.name?.includes('requests-id-chat')) {
    const ongoingRequest = requests.value.find((request) => request.state_id === 2 || request.state_id === 3);
    if (ongoingRequest) {
      navigateTo(localePath(`/requests/${ongoingRequest.id}/chat`));
    }
  }
}, {
  deep: true,
  immediate: true,
});

onMounted(() => {
  if (!route.name?.includes('requests')) {
    useNuxtApp().$store.dispatch('client/requests/getRequests');
  }
});

// Feature - clientTemporaryUserWatcher
watch([() => route.path, () => requests], () => {
  if (useAuthStore().user?.is_temp && requests.value?.length && !route.name?.includes('requests-id-chat') && !route.name?.includes('requests-id-waiting')) {
    navigateTo(localePath(`/requests/${requests.value[0].id}/chat`));
  }
}, {
  deep: true,
  immediate: true,
});
</script>

<style scoped>
main {
  &.FillHeight {
    padding-bottom: 0;
    height: 100vh;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    /* iOS */
    @supports (-webkit-touch-callout: none) {
      @media (min-height: 450px) {
        max-height: -webkit-fill-available;
      }
    }

    :deep(.page) {
      padding-bottom: 0;
    }
  }
}
</style>
