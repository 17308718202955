<template>
  <AppHeader>
    <template
      v-if="useAuthStore().user && !useAuthStore().user?.is_temp"
      #navigation
    >
      <nuxt-link
        :to="localePath('/requests')"
        exact
      >
        {{ $t('ClientAppHeader.requests') }}
      </nuxt-link>
      <nuxt-link
        v-if="!isOrganization"
        :to="localePath('/contacts')"
      >
        {{ $t('ClientAppHeader.contacts') }}
      </nuxt-link>
      <nuxt-link
        v-if="isOrganization"
        :to="localePath('/support')"
      >
        Podpora
      </nuxt-link>
      <a
        v-if="isOrganization"
        href="https://www.tichalinka.cz/navod-pro-organizace"
        target="_blank"
      >
        Návod
      </a>
    </template>
    <template #right>
      <el-button
        v-if="$i18n.locale !== 'ua'"
        class="tw-mr-5"
        link
        @click="useDialogStore().open('confirmEmergency')"
      >
        <IconCircleWrapper
          type="danger"
          class="tw-mr-3"
        >
          <icon-emergency />
        </IconCircleWrapper>
        <span class="tw-text-white">
          SOS
        </span>
      </el-button>
    </template>
  </AppHeader>
</template>

<script>
export default {
  computed: {
    isOrganization() {
      return useAuthStore().user?.type === 2;
    },
  },
};
</script>
